<template>
  <div>
    <header-slot no-border-bottom>  
    </header-slot> 
    <b-nav card-header pills class="m-0">
      <b-nav-item   
        :to="{ name: $route.matched[1].meta.InconsistentTransactionsOnlyAuthorize }"
        exact
        exact-active-class="active" 
        :active="$route.matched[2].name == $route.matched[1].meta.InconsistentTransactionsOnlyAuthorize"
        :link-classes="['px-3', bgTabsNavs]"   
        >Only Authorize
        <span class="pl-2">
          <feather-icon
            icon
            :badge="0"
            badge-classes="bg-danger"
          /> </span
      ></b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.InconsistentTransactionsOnlySoft }"
        exact
        exact-active-class="active" 
        :active="$route.matched[2].name == $route.matched[1].meta.InconsistentTransactionsOnlySoft"
        :link-classes="['px-3', bgTabsNavs]"
        >Only Soft
        <span class="ml-1">
          <feather-icon
            icon
            :badge="0"
            badge-classes="bg-danger"
          />
        </span>
      </b-nav-item> 
    </b-nav>
    <b-card
      class="border-top-primary border-3 border-table-radius px-0"
      no-body 
    >
      <router-view :key="$route.name" />
    </b-card> 
  </div>
</template>
<script> 
import { mapGetters } from "vuex";
export default {
  components: { 
  }, 
  methods: { 
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    }, 
  },
};
</script>
 